/**
 * External dependencies.
 */
import React from 'react';
import { graphql } from 'gatsby';

/**
 * Internal dependencies.
 */
import PageBase from '@page/blocks/page-base/page-base';

const PageIndex = (props) => (
	<PageBase {...props} />
);

export const query = graphql`
    query PageById($id: String) {
        page: wpPage(id: { eq: $id }) {
            id
            title
            content
            uri
            slug
            metaTitle {
                title
            }
            sectionsOptions {
                sections {
                    ... on WpPage_Sectionsoptions_Sections_Columns {
                        fieldGroupName
                        content
                        sectionName
                    }
                    ... on WpPage_Sectionsoptions_Sections_Testimonial {
                        fieldGroupName
                        testimonial {
                            ... on WpTestimonial {
                                id
                                content
                                testimonialOptions {
                                    testimonialposition
                                }
                                title
                            }
                        }
                    }
                    ... on WpPage_Sectionsoptions_Sections_Accordion {
                        fieldGroupName
                        sectionTitle
                        accordion {
                            description
                            header
                        }
                        result {
                            description
                            header
                        }
                    }
                    ... on WpPage_Sectionsoptions_Sections_FourColumns {
                        fieldGroupName
                        sectionTitle
                        list {
                            text
                        }
                    }
                    ... on WpPage_Sectionsoptions_Sections_CaseStudies {
                        fieldGroupName
                        sectionTitle
                        caseStudyPosts {
                            ... on WpCaseStudy {
                                id
                                title
                                uri
                                link
                            }
                        }
                    }
                    ... on WpPage_Sectionsoptions_Sections_CaseStudiesLogos {
                        fieldGroupName
                        sectionTitle
                        caseStudyPosts {
                            ... on WpCaseStudy {
                                databaseId
                                featuredImage {
                                    node {
                                        mediaItemUrl
                                    }
                                }
                            }
                        }
                    }
                    ... on WpPage_Sectionsoptions_Sections_Jobs {
                        fieldGroupName
                        sectionTitle
                        jobPosts {
                            ... on WpJob {
                                title
                                uri
                                databaseId
                                jobOptions {
                                    isRemote
                                }
                            }
                        }
                    }
                    ... on WpPage_Sectionsoptions_Sections_Team {
                        fieldGroupName
                        membersPosts {
                            ... on WpMember {
                                id
                                uri
                                databaseId
                                featuredImage {
                                    node {
                                        mediaItemUrl
                                    }
                                }
                                memberOptions {
                                    position
                                }
                                title
                                content
                            }
                        }
                    }
                    ... on WpPage_Sectionsoptions_Sections_Images {
                        fieldGroupName
                        images {
                            id
                            date
                            mediaItemUrl
                        }
                    }
                    ... on WpPage_Sectionsoptions_Sections_Gallery {
                        fieldGroupName
                        sectionTitle
                        images {
                            id
                            date
                            mediaItemUrl
                        }
                    }
                }
            }
        }

        menu: wpMenu(locations: {eq: GATSBY_HEADER_MENU}) {
            ...PageMenuFragment
        }

        contact: wp {
            ...PageContactFragment
        }
    }
`;

export default PageIndex;
